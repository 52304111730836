import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import withWidth from "@material-ui/core/withWidth";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlined from "@material-ui/icons/InfoTwoTone";
import SearchIcon from "@material-ui/icons/Photo";
import axios from "axios";
import moment from "moment";
import "moment/locale/nl";
import React from "react";
import MaskedInput from "react-text-mask";
import { useEffectOnce } from "react-use";
import { compose } from "recompose";
import amsterdamBord from "./amsterdam-bord.svg";
import amsterdamBuildings from "./amsterdam_gebouwen.svg";
import "./App.css";
import boot from "./boot.svg";
import dieren from "./dieren.svg";
import enschedeBord from "./enschede-bord.svg";
import gladior from "./gladior_huis.svg";
import peter from "./peter.svg";

moment.locale("nl");

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: { main: "#6c276a" },
    secondary: { main: "#fff" }
  }
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    marginTop: "20px",
    marginBottom: "30px",
    marginLeft: "10px",
    marginRight: "10px"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
};

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj &&
        typeof Symbol === "function" &&
        obj.constructor === Symbol &&
        obj !== Symbol.prototype
        ? "symbol"
        : typeof obj;
    };
  }
  return _typeof(obj);
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ("value" in descriptor) descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps);
  if (staticProps) _defineProperties(Constructor, staticProps);
  return Constructor;
}

var FlipDown = (function() {
  function FlipDown(uts) {
    var el =
      arguments.length > 1 && arguments[1] !== undefined
        ? arguments[1]
        : "flipdown";
    var opt =
      arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    _classCallCheck(this, FlipDown);

    if (typeof uts !== "number") {
      throw new Error(
        "FlipDown: Constructor expected unix timestamp, got ".concat(
          _typeof(uts),
          " instead."
        )
      );
    }

    if (_typeof(el) === "object") {
      opt = el;
      el = "flipdown";
    }

    this.version = "0.2.2";
    this.initialised = false;
    this.now = this._getTime();
    this.epoch = uts;
    this.countdownEnded = false;
    this.hasEndedCallback = null;
    this.element = document.getElementById(el);
    this.rotors = [];
    this.rotorLeafFront = [];
    this.rotorLeafRear = [];
    this.rotorTops = [];
    this.rotorBottoms = [];
    this.countdown = null;
    this.daysRemaining = 0;
    this.clockValues = {};
    this.clockStrings = {};
    this.clockValuesAsString = [];
    this.prevClockValuesAsString = [];
    this.opts = this._parseOptions(opt);

    this._setOptions();

    console.log(
      "FlipDown ".concat(this.version, " (Theme: ").concat(this.opts.theme, ")")
    );
  }

  _createClass(FlipDown, [
    {
      key: "start",
      value: function start() {
        if (!this.initialised) this._init();
        this.countdown = setInterval(this._tick.bind(this), 1000);
        return this;
      }
    },
    {
      key: "ifEnded",
      value: function ifEnded(cb) {
        this.hasEndedCallback = function() {
          cb();
          this.hasEndedCallback = null;
        };

        return this;
      }
    },
    {
      key: "_getTime",
      value: function _getTime() {
        return new Date().getTime() / 1000;
      }
    },
    {
      key: "_hasCountdownEnded",
      value: function _hasCountdownEnded() {
        if (this.epoch - this.now < 0) {
          this.countdownEnded = true;

          if (this.hasEndedCallback != null) {
            this.hasEndedCallback();
            this.hasEndedCallback = null;
          }

          return true;
        } else {
          this.countdownEnded = false;
          return false;
        }
      }
    },
    {
      key: "_parseOptions",
      value: function _parseOptions(opt) {
        return {
          theme: opt.hasOwnProperty("theme") ? opt.theme : "dark"
        };
      }
    },
    {
      key: "_setOptions",
      value: function _setOptions() {
        this.element.classList.add("flipdown__theme-".concat(this.opts.theme));
      }
    },
    {
      key: "_init",
      value: function _init() {
        this.initialised = true;

        if (this._hasCountdownEnded()) {
          this.daysremaining = 0;
        } else {
          this.daysremaining = Math.floor(
            (this.epoch - this.now) / 86400
          ).toString().length;
        }

        var dayRotorCount = this.daysremaining <= 2 ? 2 : this.daysremaining;

        for (var i = 0; i < dayRotorCount + 6; i++) {
          this.rotors.push(this._createRotor(0));
        }

        var dayRotors = [];

        for (var i = 0; i < dayRotorCount; i++) {
          dayRotors.push(this.rotors[i]);
        }

        this.element.appendChild(this._createRotorGroup(dayRotors));
        var count = dayRotorCount;

        for (var i = 0; i < 3; i++) {
          var otherRotors = [];

          for (var j = 0; j < 2; j++) {
            otherRotors.push(this.rotors[count]);
            count++;
          }

          this.element.appendChild(this._createRotorGroup(otherRotors));
        }

        this.rotorLeafFront = Array.prototype.slice.call(
          this.element.getElementsByClassName("rotor-leaf-front")
        );
        this.rotorLeafRear = Array.prototype.slice.call(
          this.element.getElementsByClassName("rotor-leaf-rear")
        );
        this.rotorTop = Array.prototype.slice.call(
          this.element.getElementsByClassName("rotor-top")
        );
        this.rotorBottom = Array.prototype.slice.call(
          this.element.getElementsByClassName("rotor-bottom")
        );

        this._tick();

        this._updateClockValues(true);

        return this;
      }
    },
    {
      key: "_createRotorGroup",
      value: function _createRotorGroup(rotors) {
        var rotorGroup = document.createElement("div");
        rotorGroup.className = "rotor-group";
        var dayRotorGroupHeading = document.createElement("div");
        dayRotorGroupHeading.className = "rotor-group-heading";
        rotorGroup.appendChild(dayRotorGroupHeading);
        appendChildren(rotorGroup, rotors);
        return rotorGroup;
      }
    },
    {
      key: "_createRotor",
      value: function _createRotor() {
        var v =
          arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        var rotor = document.createElement("div");
        var rotorLeaf = document.createElement("div");
        var rotorLeafRear = document.createElement("figure");
        var rotorLeafFront = document.createElement("figure");
        var rotorTop = document.createElement("div");
        var rotorBottom = document.createElement("div");
        rotor.className = "rotor";
        rotorLeaf.className = "rotor-leaf";
        rotorLeafRear.className = "rotor-leaf-rear";
        rotorLeafFront.className = "rotor-leaf-front";
        rotorTop.className = "rotor-top";
        rotorBottom.className = "rotor-bottom";
        rotorLeafRear.textContent = v;
        rotorTop.textContent = v;
        rotorBottom.textContent = v;
        appendChildren(rotor, [rotorLeaf, rotorTop, rotorBottom]);
        appendChildren(rotorLeaf, [rotorLeafRear, rotorLeafFront]);
        return rotor;
      }
    },
    {
      key: "_tick",
      value: function _tick() {
        this.now = this._getTime();
        var diff = this.epoch - this.now <= 0 ? 0 : this.epoch - this.now;
        this.clockValues.d = Math.floor(diff / 86400);
        diff -= this.clockValues.d * 86400;
        this.clockValues.h = Math.floor(diff / 3600);
        diff -= this.clockValues.h * 3600;
        this.clockValues.m = Math.floor(diff / 60);
        diff -= this.clockValues.m * 60;
        this.clockValues.s = Math.floor(diff);

        this._updateClockValues();

        this._hasCountdownEnded();
      }
    },
    {
      key: "_updateClockValues",
      value: function _updateClockValues() {
        var _this = this;

        var init =
          arguments.length > 0 && arguments[0] !== undefined
            ? arguments[0]
            : false;
        this.clockStrings.d = pad(this.clockValues.d, 2);
        this.clockStrings.h = pad(this.clockValues.h, 2);
        this.clockStrings.m = pad(this.clockValues.m, 2);
        this.clockStrings.s = pad(this.clockValues.s, 2);
        this.clockValuesAsString = (
          this.clockStrings.d +
          this.clockStrings.h +
          this.clockStrings.m +
          this.clockStrings.s
        ).split("");
        this.rotorLeafFront.forEach(function(el, i) {
          el.textContent = _this.prevClockValuesAsString[i];
        });
        this.rotorBottom.forEach(function(el, i) {
          el.textContent = _this.prevClockValuesAsString[i];
        });

        function rotorTopFlip() {
          var _this2 = this;

          this.rotorTop.forEach(function(el, i) {
            if (el.textContent != _this2.clockValuesAsString[i]) {
              el.textContent = _this2.clockValuesAsString[i];
            }
          });
        }

        function rotorLeafRearFlip() {
          var _this3 = this;

          this.rotorLeafRear.forEach(function(el, i) {
            if (el.textContent != _this3.clockValuesAsString[i]) {
              el.textContent = _this3.clockValuesAsString[i];
              el.parentElement.classList.add("flipped");
              var flip = setInterval(
                function() {
                  el.parentElement.classList.remove("flipped");
                  clearInterval(flip);
                }.bind(_this3),
                500
              );
            }
          });
        }

        if (!init) {
          setTimeout(rotorTopFlip.bind(this), 500);
          setTimeout(rotorLeafRearFlip.bind(this), 500);
        } else {
          rotorTopFlip.call(this);
          rotorLeafRearFlip.call(this);
        }

        this.prevClockValuesAsString = this.clockValuesAsString;
      }
    }
  ]);

  return FlipDown;
})();

function pad(n, len) {
  n = n.toString();
  return n.length < len ? pad("0" + n, len) : n;
}

function appendChildren(parent, children) {
  children.forEach(function(el) {
    parent.appendChild(el);
  });
}

function App(props) {
  const { classes, width } = props;

  const { fullScreen } = props;

  const [values, setValues] = React.useState({
    textmask: "",
    numberformat: "1320"
  });

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);

  const [content, setContent] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const [countdownText, setCountdownText] = React.useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpenInfoDialog() {
    setOpenInfoDialog(true);
  }

  function handleCloseInfoDialog() {
    setOpenInfoDialog(false);
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  function handleSnackbarClick() {
    setOpenSnackbar(true);
  }

  function handleSnackbarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  }

  // eslint-disable-next-line no-undef

  useEffectOnce(() => {
    // Unix timestamp (in seconds) to count down to
    let countDownTimeStamp = new Date().getTime() / 1000;

    let firstDay = moment("2019-06-11 6:00", "YYYY-MM-DD HH:mm");
    let today = new moment();

    if (today.isBefore(firstDay)) {
      countDownTimeStamp = firstDay.valueOf() / 1000;
      setCountdownText("Totdat Peter weer naar Enschede gaat 😔");
    } else {
      const dayINeed = 2;
      const todayIsoWeekDay = moment().isoWeekday();

      let backInAmsterdamTime = new Date();
      backInAmsterdamTime.setHours(19);
      backInAmsterdamTime.setMinutes(30);
      backInAmsterdamTime.setSeconds(0);
      backInAmsterdamTime.setMilliseconds(0);

      let leavingTimeMoment = moment(backInAmsterdamTime).isoWeekday(dayINeed);

      if (
        (todayIsoWeekDay === 1 || todayIsoWeekDay === 2) &&
        today.isBefore(leavingTimeMoment)
      ) {
        // then just give me this week's instance of that day
        countDownTimeStamp = leavingTimeMoment.valueOf() / 1000;
        setCountdownText("Totdat Peter weer thuis is in Amsterdam");
      } else {
        // otherwise, give me *next week's* instance of that same day
        let currentTime = new Date();
        currentTime.setHours(6);
        currentTime.setMinutes(0);
        currentTime.setSeconds(0);
        currentTime.setMilliseconds(0);

        setCountdownText("Totdat Peter weer naar Enschede gaat 😔");

        countDownTimeStamp =
          moment(currentTime)
            .add(1, "weeks")
            .isoWeekday(1)
            .valueOf() / 1000;
      }
    }

    new FlipDown(countDownTimeStamp).start();
  });

  function getContent(code) {
    axios
      .get(
        `https://6hp6ny6m62.execute-api.eu-west-1.amazonaws.com/prod/content?code=${code}`,
        { headers: { "x-api-key": "12YyRofrZF8sy6qsS4Nh296SKdE6IlMmaJOj6IPH" } }
      )
      .then(function(response) {
        // handle success
        console.log(response.data);
        setContent(response.data.content);
        setOpen(true);
      })
      .catch(function(error) {
        // handle error
        setOpenSnackbar(true);
        console.log(error);
      });
  }

  function handleSubmit(e) {
    if (e) {
      if (e.key === undefined || e.key === "Enter") {
        getContent(values.textmask);
      }
    } else {
      getContent(values.textmask);
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          {content.map((contentItem, index) => {
            if (contentItem.type === "PHOTO") {
              return (
                <div key={"content" + index}>
                  <img
                    alt="foto peter"
                    style={{ width: "100%" }}
                    src={contentItem.url}
                  />
                </div>
              );
            } else if (contentItem.type === "VIDEO") {
              return (
                <div key={"content" + index}>
                  <video width="320" controls autoplay>
                    <source src={contentItem.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              );
            } else {
              return null;
            }
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Sluit venster
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openInfoDialog}
        onClose={handleCloseInfoDialog}
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Persbericht
          </Typography>
          <Divider style={{ marginBottom: "0.35em" }} light />

          <Typography variant="subtitle2" gutterBottom>
            ENSCHEDE – Online marketing bureau Gladior B.V. uit Enschede
            lanceert een unieke tool: "Is Peter al Thuis?". Met deze tool kan
            Vera bijhouden wanneer Peter weer veilig thuis is in Amsterdam.
          </Typography>
          <Typography color="textSecondary" variant="subtitle2" gutterBottom>
            Vanwege de omgangsregeling van Peter tussen Vera en Gladior, heeft
            Gladior een unieke tool gelanceerd. Met deze tool kan Vera precies
            zien wanneer ze Peter weer terug kan verwachten in Amsterdam.
          </Typography>
          <Typography color="textSecondary" variant="subtitle2" gutterBottom>
            „Namens alle collega's van de Gladior wensen wij jullie veel geluk
            samen!” vertelt directeur Marcel Brandriet van Gladior - Online
            marketing.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary">
            Sluit venster
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ minHeight: "100vh" }} className="App layout flex vertical">
        <div
          className="flex layout horizontal wrap end-justified "
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%"
          }}
        >
          <IconButton onClick={handleClickOpenInfoDialog}>
            <InfoOutlined color="secondary" fontSize="large" />
          </IconButton>
        </div>

        {width !== "sm" && width !== "xs" ? (
          <div
            style={{
              minHeight: "50px",

              backgroundColor: "#acacff"
            }}
            className="flex layout horizontal"
          />
        ) : null}

        <div
          style={
            width !== "sm" && width !== "xs"
              ? {
                  minHeight: "250px",

                  backgroundColor: "#acacff",
                  backgroundImage: "url(./Lucht.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "right center"
                }
              : { backgroundColor: "#acacff", minHeight: "250px" }
          }
          className="flex layout horizontal"
        >
          <div
            style={{
              backgroundImage: "url(./Lucht_gebouwen.svg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "left bottom"
            }}
            className={
              width !== "sm" && width !== "xs"
                ? "flex-1"
                : "layout vertical flex flex-1 end-justified center"
            }
          >
            {width !== "sm" && width !== "xs" ? null : (
              <img
                alt=""
                style={{ height: "150px", marginBottom: "-10px" }}
                src={gladior}
              />
            )}
            <span />
          </div>
          {width !== "sm" && width !== "xs" ? (
            <div style={{}} className="flex-1">
              <span />
            </div>
          ) : null}
          {width !== "sm" && width !== "xs" ? (
            <div className="flex-1">
              <span />
            </div>
          ) : null}
        </div>

        <div
          style={
            width !== "sm" && width !== "xs"
              ? {
                  marginTop: "-7em"
                }
              : {}
          }
          className="flex layout horizontal wrap"
        >
          {width !== "sm" && width !== "xs" ? (
            <div className="layout vertical flex flex-1 end-justified">
              <img
                alt=""
                style={{ minHeight: "250px" }}
                src={amsterdamBuildings}
              />
            </div>
          ) : null}
          <div
            className="layout flex-2 vertical center-center"
            style={{
              backgroundImage: "url(./grond.svg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              minHeight: "250px"
            }}
          >
            <div id="flipdown" className="flipdown" />
            <span
              style={{
                fontFamily: `'Lato', sans-serif`,
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "30px",
                marginTop: "20px",
                color: "#fff"
              }}
            >
              {countdownText}
            </span>
            <div
              className="layout flex horizontal center-center"
              style={{ padding: "0px 5px", width: "100%" }}
            >
              <Paper className={classes.root} elevation={1}>
                <InputBase
                  value={values.textmask}
                  className={classes.input}
                  placeholder="xxxx-xxxx-xxxx-xxxx"
                  onChange={handleChange("textmask")}
                  onKeyPress={e => {
                    handleSubmit(e);
                  }}
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom}
                />
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </div>
          {width !== "sm" && width !== "xs" ? (
            <div className="layout vertical flex flex-1 end-justified">
              <img
                alt=""
                style={{ minHeight: "250px", marginTop: "-4em" }}
                src={gladior}
              />
            </div>
          ) : null}
        </div>

        <div
          style={width !== "sm" && width !== "xs" ? { marginTop: "-4em" } : {}}
          className={
            width !== "sm" && width !== "xs"
              ? "flex layout horizontal wrap"
              : "flex layout vertical wrap"
          }
        >
          <div className=" layout flex flex-1 horizontal end-justified">
            <img
              alt=""
              style={
                width !== "sm" && width !== "xs"
                  ? { height: "200px" }
                  : { width: "100%" }
              }
              src={boot}
            />
          </div>
          <div className="flex-1 layout horizontal center-center ">
            <span />
          </div>
          <div className=" layout flex flex-1 horizontal start-justified">
            <img
              alt=""
              style={
                width !== "sm" && width !== "xs"
                  ? { height: "200px" }
                  : { width: "100%" }
              }
              src={dieren}
            />
          </div>
        </div>

        {width !== "sm" && width !== "xs" ? (
          <div
            style={{ minHeight: "200px" }}
            className="flex layout horizontal wrap"
          />
        ) : null}

        {width !== "sm" && width !== "xs" ? (
          <div
            className="flex layout horizontal wrap"
            style={{
              position: "fixed",
              left: "0px",
              bottom: "0px",
              width: "100%"
            }}
          >
            <div className="layout vertical flex flex-1 end-justified">
              <img alt="" style={{ height: "200px" }} src={amsterdamBord} />
            </div>
            <div className="layout vertical flex flex-1 end-justified center">
              <img alt="" style={{ width: "400px" }} src={peter} />
            </div>
            <div className="layout vertical flex flex-1 end-justified">
              <img alt="" style={{ height: "200px" }} src={enschedeBord} />
            </div>
          </div>
        ) : (
          <div className="flex layout horizontal wrap">
            <div className="layout vertical flex flex-1 end-justified center">
              <img alt="" style={{ width: "400px" }} src={peter} />
            </div>
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Helaas. code is niet geldig</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </MuiThemeProvider>
  );
}

export default compose(
  withWidth(),
  withMobileDialog(),
  withStyles(styles)
)(App);
